import classNames from 'classnames/bind';
import React from 'react';
import PropType from 'prop-types';

import BigCta from 'wooclap/components/page-sections/big-cta/BigCta';
import Button from 'uikit/Button';
import FAQ from 'components/Pricing/FAQ';
import Icon from 'uikit/Icon';
import Layout from 'components/Layout';
import Testimony from 'components/Shared/Testimony';

import Canope from 'assets/images/landing-tne/canope.png';
import BanqueDesTerritoires from 'assets/images/landing-tne/banque-des-territoires.png';
import EducationNationale from 'assets/images/landing-tne/education-nationale.png';
import France2030 from 'assets/images/landing-tne/france2030.png';
import Tuto1 from 'assets/images/landing-tne/tuto1.png';
import Tuto2 from 'assets/images/landing-tne/tuto2.jpg';
import Tuto3 from 'assets/images/landing-tne/tuto3.png';

import styles from './LandingTNE.module.scss';

const cx = classNames.bind(styles);

const IndexPage = ({ pageContext: { lang, layout, content, urls } }) => (
  <Layout lang={lang} t={layout} urls={urls}>
    <main className={cx('main')}>
      <div className={cx('bubbles')}>
        <div className={cx('page-title')}>{content.pageTitle}</div>
        <section className={cx('student-progress')}>
          <div className={cx('text-bloc', 'student-progress-bloc')}>
            <div className={cx('student-progress-title')}>
              {content.bloc1Title.toUpperCase()}
            </div>
            <p className={cx('student-progress-paragraph')}>
              {content.bloc1Text}
            </p>
            <div className={cx('actions')}>
              <Button
                as="a"
                className={cx('cta-button')}
                href={content.CTA.link}
                intent="primary"
                size="large"
                target="blank"
              >
                {content.CTA.label}
              </Button>
            </div>
          </div>
          <div className={cx('iframe-wrapper')}>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              frameBorder="0"
              height="315"
              src="https://www.youtube-nocookie.com/embed/g_Qyz-vNBBY?si=X_FDJ3LvXpjHSFJo&amp;controls=0"
              title="YouTube video player"
              width="560"
            />
          </div>
        </section>
        <section>
          <h2 className={cx('cards-title')}>{content.bloc2Title}</h2>
          <div className={cx('cards')}>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="laugh-wink" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part1Title}</h3>
              <p className={cx('paragraph')}>{content.bloc2Part1Text1}</p>
              <p className={cx('paragraph')}>{content.bloc2Part1Text2}</p>
              <p className={cx('paragraph')}>{content.bloc2Part1Text3}</p>
              <div className={cx('image-wrapper')}>
                <img alt="tutoriel-1" className={cx('image')} src={Tuto1} />
              </div>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="brain" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part2Title}</h3>
              <p className={cx('paragraph')}>{content.bloc2Part2Text1}</p>
              <p className={cx('paragraph')}>{content.bloc2Part2Text2}</p>
              <div className={cx('image-wrapper')}>
                <img
                  alt="tutoriel-2"
                  className={cx('image', 'space-top')}
                  src={Tuto2}
                />
              </div>
            </div>
            <div className={cx('card')}>
              <div className={cx('card-icon')}>
                <Icon className={cx('icon')} name="rocket" />
              </div>
              <h3 className={cx('card-title')}>{content.bloc2Part3Title}</h3>
              <p className={cx('paragraph')}>{content.bloc2Part3Text}</p>
              <div className={cx('image-wrapper')}>
                <img alt="tutoriel-3" className={cx('image')} src={Tuto3} />
              </div>
            </div>
          </div>
          <div className={cx('cta-button-wrapper')}>
            <Button
              as="a"
              className={cx('cta-button-cards')}
              href={content.CTA.link}
              intent="primary"
              size="large"
              target="blank"
            >
              {content.CTA.label}
            </Button>
          </div>
        </section>
        <section className={cx('testimony')}>
          <h2 className={cx('testimony-title')}>{content.testimonyTitle}</h2>
          <div className={cx('testimonies')}>
            <Testimony isSmallCard content={content.testimony1} />
            <Testimony isSmallCard content={content.testimony2} />
            <Testimony isSmallCard content={content.testimony3} />
          </div>
          <section className={cx('big-cta')}>
            <BigCta
              title={content.bigCta.title}
              intro={content.bigCta.intro}
              link={content.bigCta.link}
              theme={content.bigCta.theme}
            />
          </section>
        </section>
        <section className={cx('faq')}>
          <h2 className={cx('faq-title')}>{content.faqSection.faqTitle}</h2>
          <div className={cx('faq-list')}>
            {Object.values(content.faqSection.questionList).map((question) => (
              <FAQ hasHtml content={question} />
            ))}
          </div>
        </section>
        <section className={cx('partners')}>
          <div className={cx('partner-cards')}>
            {[France2030, EducationNationale, BanqueDesTerritoires, Canope].map(
              (image) => (
                <div className={cx('partner-card')}>
                  <img
                    alt=""
                    className={cx('partner-card-image')}
                    src={image}
                  />
                </div>
              ),
            )}
          </div>
        </section>
      </div>
    </main>
  </Layout>
);

IndexPage.propTypes = {
  pageContext: PropType.object.isRequired,
};

export default IndexPage;
